import request from '../utils/request'
export const getCategorys = () => {
    return request({
        url: "/scratch_categorys",
        method: "get",
    });
}

export const getThemeWorks = () => {
    return request({
        url: "/theme_works",
        method: "get",
    });
}
export const gameLog = (data:{
    store_id:number,
    log_type:string,
    begin_at:string,
    save_at:string,
}) => {
    return request({
        url: "/game_log/",
        method: "post",
        data
    });
}

export const storeLogin = (data: {
    store_no: string,
    store_password: string
    login_type?: number
}) => {
    return request({
        url: "/store_login/",
        method: "post",
        data
    });
}

export const deviceLoginLog = (data: {
    platform_type: string,
    device_name: string,
    operating_system: string,
    browser: string,
}) => {
    return request({
        url: "/device_login_log/",
        method: "post",
        data
    });
}

export const uploadBiologyV2 = (data:{
    operating_system:string,
    browser: string,
    fish_image: string,
    image_src: string
}) => {
    return request({
        url: `/radar/upload_biology_v2/`,
        method: "post",
        data
    });
}

// https://api.dev.longan.eliteu.xyz/api/v1/products/{product_code}/
export const getVersion = () => {
   if (process.env.NODE_ENV === 'development') {
    const baseUrl = 'https://api.dev.longan.eliteu.xyz'
    return fetch(baseUrl + '/api/v1/products/hdty-wall/').then(res => res.json())
   }
   return fetch('/api/v1/products/hdty-wall/').then(res => res.json())
}
<template>
  <div class="browser-tips" v-show="isShowTips">
    请使用 <a href="https://www.google.cn/intl/zh-CN/chrome/" target="_blank"> Google Chrome </a>浏览器运行本平台，否则将遇到异常。点击蓝字下载
    <CloseOutlined class="browser-tips-close" @click="hideTips"/>
  </div>
</template>
<script setup lang="ts">
  import { CloseOutlined } from '@ant-design/icons-vue'
  import { getBroswerName } from '@/utils';
  import { ref } from 'vue'

  // 判断浏览器类型
  const browserName = getBroswerName()
  // 是否显示提示语
  const isShowTips = ref(browserName !== 'Chrome' && browserName !== 'Microsoft Edge')
  // 隐藏提示语
  const hideTips = () => {
    isShowTips.value = false
  }
</script>
<style lang="scss" scoped>
  $--top: 270px;
  .browser-tips {
    -webkit-animation: fadein 0.5s;
    animation: fadein 0.5s;
    white-space: nowrap;
    border-radius: 4px;
    background-color: white;
    color: rgba(0, 0, 0, 0.65);
    text-align: center;
    border-radius: 2px;
    padding: 10px 16px;
    position: fixed;
    z-index: 99999;
    left: 50%;
    transform: translateX(-50%);
    top: $--top;
    font-size: 14px;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
      0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    a {
      text-decoration: none;
      color: #376fb3;
    }
    .browser-tips-close {
      margin-left: 8px;
    }
  }
  @-webkit-keyframes fadein {
    from {
      top: calc(#{$--top} - 16px);
      opacity: 0;
    }
    to {
      top: $--top;
      opacity: 1;
    }
  }

  @keyframes fadein {
    from {
      top: calc(#{$--top} - 16px);
      opacity: 0;
    }
    to {
      top: $--top;
      opacity: 1;
    }
  }
</style>
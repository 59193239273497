<template lang="">
    <div class="theaters container">
        <h3>{{ campusInfo.name }} - 互动投影（墙面版）</h3>
        <a-spin  size="large" :spinning="loading">
            <div class="list">
                <div class="item" v-for="(work, index) in works" :key="index" @click="toCourse(work)">
                    <img :src="work.image_url" alt="">
                    <p>{{work.name}}</p>
                </div>
            </div>
        </a-spin>
    </div>
</template>
<script>
import { reactive, ref } from 'vue';

import store from '@/store';
import router from '@/router';
import { getThemeWorks } from '@/api';
export default {
    name: 'TheatersPage',
    setup() {
        const loading = ref(true)
        const campusInfo = reactive(store.state.campusInfo)
        const works = ref([])
        console.log('store.state.campusInfo', store.state.campusInfo);
        getThemeWorks().then((res) => {
            console.log('res', res);
            works.value = res.data
            loading.value = false
        }).catch(() => {
            loading.value = false
        })
        const toCourse = async (work) => {
            await store.commit('updateThemeInfo', work)
            await router.push('/course')
        }
        return {
            campusInfo,
            toCourse,
            loading,
            works
        }
    }

}
</script>
<style lang="scss">
.theaters {
    h3 {
        font-weight: 600;
        font-size: 28px;
        color: #333;
        text-align: center;
        margin-top: 42px;
        margin-bottom: 0;
    }

    .list {
        display: flex;
        padding: 42px 55px 80px;
        flex-wrap: wrap;
        justify-content: center;

        .item {
            width: 31.5%;
            text-align: left;
            height: 270px;
            background: #fff;
            border-radius: 10px;
            overflow: hidden;
            cursor: pointer;
            &:hover{
                box-shadow: 0 2px 20px 0 rgba(0, 0, 0, .1);
                p{
                    color: var(--font-color-link);
                }
            }

            img {
                width: 100%;
                height: 224px;
                object-fit: cover;
            }

            p {
                padding: 16px;
                font-size: 14px;
                color: #333;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }
        }

    }
}
</style>
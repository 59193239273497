<template>
  <a-config-provider :locale="locale">
    <BrowserTips />
    <Header></Header>
    <router-view />
    <eliteuServiceVue3 />
  </a-config-provider>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
// import CustomerService from '@/components/CustomerService.vue'
import Header from '@/components/Header.vue'
import BrowserTips from '@/components/BrowserTips.vue'
import zhCN from 'ant-design-vue/es/locale/zh_CN';
import eliteuServiceVue3 from 'eliteu-service-vue3'
export default defineComponent({
  name: 'IndexPage',
  components: {
    Header,
    eliteuServiceVue3,
    BrowserTips
  },
  data(){
    return {
      locale: zhCN,
    }
  }
});
</script>

<style lang="scss">
@import url('./App.scss');

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  padding: 96px 0 60px;
}

.link {
  width: 100%;
  position: fixed;
  bottom: 0;
  font-size: 14px;
  text-align: center;
  background: #fff;
  margin-bottom: 0;
  padding: 10px 0;

  a {
    color: var(--font-color-link);
  }

}
</style>

import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "browser-tips" }

import { CloseOutlined } from '@ant-design/icons-vue'
  import { getBroswerName } from '@/utils';
  import { ref } from 'vue'

  // 判断浏览器类型
  
export default /*@__PURE__*/_defineComponent({
  __name: 'BrowserTips',
  setup(__props) {

  const browserName = getBroswerName()
  // 是否显示提示语
  const isShowTips = ref(browserName !== 'Chrome' && browserName !== 'Microsoft Edge')
  // 隐藏提示语
  const hideTips = () => {
    isShowTips.value = false
  }

return (_ctx: any,_cache: any) => {
  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createTextVNode(" 请使用 ")),
    _cache[1] || (_cache[1] = _createElementVNode("a", {
      href: "https://www.google.cn/intl/zh-CN/chrome/",
      target: "_blank"
    }, " Google Chrome ", -1)),
    _cache[2] || (_cache[2] = _createTextVNode("浏览器运行本平台，否则将遇到异常。点击蓝字下载 ")),
    _createVNode(_unref(CloseOutlined), {
      class: "browser-tips-close",
      onClick: hideTips
    })
  ], 512)), [
    [_vShow, isShowTips.value]
  ])
}
}

})
<template lang="">
    <div class="course container">
        <h3>{{ campusInfo.name }} - {{ themeInfo.name }}</h3>
        <div class="title">
            <a-steps
                progress-dot
                :current="curNav"
                size="small"
                :items="titles.map(m=>({title:m}))"
                @change="updateNav"
            ></a-steps>
        </div>
        <div>
            <div v-for="(course, index) in courseList[curNav]" :key="index" >
                <CourseItem :title="course.title" :desc="course.desc" :imgs="course.imgs" :index="index" :tips="course.tips" :curNav="curNav"/>
            </div>
        </div>
            <div class="complete" v-if="curNav<3" @click="updateNav(curNav+1)">我完成了，去下一步</div>
    </div>
    <div class="bottom">
        <div class="bottom-upper">
            <a-tooltip title="首次使用这个产品吗？先看看教程吧" :open="isFirst" :getPopupContainer="getPopupContainer">
                <span class="bottom-text" @click="handleLink(themeInfo.video_url)" target="_blank">{{themeInfo.video_name}}</span>
            </a-tooltip>
            <span class="bottom-line"></span>
            <span class="bottom-version">产品版本：{{version}}</span>
        </div>
        <div class="bottom-lower">
            互动投影是使用图形化编程语言结合激光雷达实现的超大平面触控体验创客项目，可以在数平方米的平面上投射影像并支持多人互动，营造极具冲击力的沉浸式体验
        </div>
    </div>
</template>
<script>
// 接口请求回来的link
// 数组第几个的title = ''

import p1 from '@/assets/images/1.png'
import p2 from '@/assets/images/2.png'
import p3 from '@/assets/images/3.png'
import p4 from '@/assets/images/4.png'
import p5 from '@/assets/images/5.png'
import p6 from '@/assets/images/6.png'
import p7 from '@/assets/images/7.png'
import p8 from '@/assets/images/8.png'
import p9 from '@/assets/images/9.png'
import p10 from '@/assets/images/10.png'
import p11 from '@/assets/images/11.png'
import p12 from '@/assets/images/12.png'
import p13 from '@/assets/images/13.png'
import p14 from '@/assets/images/14.png'
import p15 from '@/assets/images/15.png'
import p16 from '@/assets/images/16.png'
import p17 from '@/assets/images/17.png'
import p18 from '@/assets/images/18.png'
import p19 from '@/assets/images/19.png'
import p20 from '@/assets/images/20.png'
import p21 from '@/assets/images/21.png'
import p22 from '@/assets/images/22.png'
import p23 from '@/assets/images/23.png'
import p24 from '@/assets/images/24.png'
import p25 from '@/assets/images/25.png'
// const courseListData = 
import { ref, reactive, onMounted } from 'vue';
import CourseItem from '@/components/CourseItem.vue';
import store from '@/store';
import { getVersion } from '@/api';
import {useVisitRecord} from '@/utils'
import { computed } from 'vue'
export default {
    name: 'CoursePage',
    components: { CourseItem },
    setup() {
        const curNav = ref(0)
        const campusInfo = reactive(store.state.campusInfo)
        const themeInfo = reactive(store.state.themeInfo)
        const courseList = reactive([
            [
                {
                    title: '打开高拍仪并点击按钮，调整到最大亮度。',
                    desc: '',
                    imgs: [p1]
                },
                {
                    title: '确保激光雷达插座开关、音响主机都处于开启状态（日常可以不用关）。',
                    desc: '',
                    imgs: [p2]
                },
                {
                    title: '用遥控器打开投影仪。按「Windows 徽标键 田+P」将投影模式设为「扩展」，直到在墙面上可以看到系统桌面的画面。',
                    desc: '',
                    imgs: [p3, p4, p5]
                }
            ],
            [
                {
                    title: '打开英荔自研工具<a href="adapterOpen://" target="_blank">「英荔 Adapter」</a>，开启后图标会显示在任务栏，而不会弹出任何界面。',
                    desc: '',
                    tips: `<div class="course-tips">
                            <div>点击上方的蓝色文字，Adapter 没有启动？可能是因为本机没有安装最新版的 Adapter，点击
                                <a href="https://ecourse.aimaker.space/%E6%95%99%E5%AD%A6%E8%BD%AF%E4%BB%B6%E7%AE%A1%E7%90%86/%E8%BD%AF%E4%BB%B6%E5%AE%89%E8%A3%85%E5%8C%85/Adapter_V2.3.0.exe" class="course-click">下载</a></div>
                            <div>互动投影目前只支持在 Windows10 1809 以上操作系统中运行，不会安装可以点击
                                <a target="_blank" href="https://1251349076.vod2.myqcloud.com/45e704edvodtransgzp1251349076/03b9a41f1397757896759347405/v.f40672.mp4" class="course-click">查看教程</a></div>
                        </div>`,
                    imgs: [p6, p7]
                },
                {
                    title: '打开激光雷达软件「雷达模拟鼠标」，待开启完成后（一般会自动最小化）点开其主界面。',
                    desc: '',
                    imgs: [p8, p9, p10]
                },
                {
                    title: '此时触摸墙面，可以在激光雷达软件界面上看到相应反馈（显示你触碰的位置）。',
                    desc: '',
                    imgs: [p11, p12]
                }
            ],
            [
                {
                    title: `点击打开<a href="${themeInfo.work_url}" target="_blank">${themeInfo.work_name}</a>页面，待其加载完成，然后把作品标签页（一般是向右）拖动到墙上投影仪投出的画面中。`,
                    desc: '预计网页加载时间在 1 分钟以上，请耐心等待。',
                    imgs: [p13, p14,p15,p16]
                },
                {
                    title: '把浏览器设为全屏，然后把互动剧场作品设为全屏。',
                    desc: '',
                    imgs: [p17, p18,p19]
                },
                {
                    title: '点击绿旗，运行互动剧场作品（此时界面不会有任何反应，只是完成了初始化）。',
                    desc: '',
                    imgs: [p20]
                }
            ],
            [
                {
                    title: `点击打开<a href="${window.location.origin}/identify" target="_blank"> AI 识别涂色卡</a>页面。`,
                    desc: '需要授权允许网页使用摄像头的权限。',
                    imgs: [p24]
                },
                {
                    title: '从柜子里拿出涂色卡备用。',
                    desc: '涂色卡成本较高，建议每次不要拿太多涂色卡出来，避免损坏或遗失。每张涂色卡都有唯一编码，无法复印使用。如涂色卡即将用尽请联系英荔集团销售人员申请采购。',
                    imgs: [p25]
                },
                {
                    title: `首次使用，或是遇到了奇怪的问题可以看一下<a href="${themeInfo.manual_url}" target="_blank">《${themeInfo.manual_name}》</a>。到此准备工作完成，可以开始 show 了。`,
                    desc: '完整的互动剧场演示流程如文档中所述。建议熟练掌握后再开始面向学生演示。',
                    imgs: [p21, p22]
                }
            ],
        ])
        const titles = ref([
            '开启相关硬件', '启动基础软件', '打开剧场程序', '打开识别涂色卡页'
        ])
        const updateNav = (index) => {
            curNav.value = index
        }
        // 获取系统版本号
        const version = ref()
        onMounted(async () => {
            const result = await getVersion()
            version.value = result.version
        })
        const getPopupContainer = () => {
            return document.querySelector('.bottom')
        }
        const {has: hasVisitRecord, save: saveVisitRecord } = useVisitRecord()
        // 是否首次使用
        const isFirst = computed(() => {
            const isHas = hasVisitRecord(campusInfo.store_id)
            return !isHas
        })
        const handleLink = (link) => {
            saveVisitRecord(campusInfo.store_id)
            window.open(link, '_blank')
        }
        return {
            curNav,
            titles,
            courseList,
            updateNav,
            campusInfo,
            themeInfo,
            getPopupContainer,
            version,
            isFirst,
            handleLink
        }
    }
}
</script>
<style lang="scss" scoped>
.course {
    padding-bottom: 138px;
    h3 {
            font-weight: 600;
            font-size: 28px;
            color: #333;
            text-align: center;
            margin-top: 42px;
            margin-bottom: 0;
        }
    .title {
        width: 65%;
        margin: 46px auto 33px;
        .active {
            color: pink;
        }
        // :deep .ant-steps.ant-steps-dot .ant-steps-item-icon, .ant-steps.ant-steps-dot.ant-steps-small .ant-steps-item-icon {
        //     width: 12px;
        //     height: 12px;
        //     box-sizing: content-box;
        //     // .ant-steps-icon-dot{

        //     // border: 3px solid #fff
        //     // }
        // }
        // :deep .ant-steps.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot, .ant-steps.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot{
        //     border: 2px solid #fff;
        // }
        // :deep .ant-steps.ant-steps-dot .ant-steps-item-tail::after, .ant-steps.ant-steps-dot.ant-steps-small .ant-steps-item-tail::after{
        //     height: 2px;

        // }
        // :deep .ant-steps.ant-steps-dot .ant-steps-item-tail, .ant-steps.ant-steps-dot.ant-steps-small .ant-steps-item-tail{
        //     top: 5px;
        // }
        :deep(.ant-steps .ant-steps-item-icon .ant-steps-icon){
            top: -2px;
        }
    }
        .complete {
            width: fit-content;
            color: #fff;
            background: #0084FF;
            border-radius: 4px;
            padding: 11px 38px 10px;
            margin: 30px auto 0;
            cursor: pointer;
        }


}
.bottom {
    position: fixed;
    padding-top: 11px;
    bottom: 0;
    height: 67px;
    width: 100%;
    background: #fff;
    text-align: center;
    font-size: 16px;

    a {

        color: var(--font-color-link);
    }


    >div {

        cursor: pointer;
    }
    .bottom-text {
        height: 16px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 16px;
        color: #0084FF;
        line-height: 16px;
        text-align: right;
        font-style: normal;
    }
    .bottom-upper {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .bottom-lower {
        line-height: 40px;
        height: 14px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #999999;
        text-align: center;
        font-style: normal;
    }
    .bottom-line {
        display: inline-block;
        width: 1px;
        height: 11px;
        background: #E9E9E9;
        border-radius: 1px;
        margin: 0 10px;
    }
    .bottom-version {
        height: 16px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 16px;
        color: #999999;
        line-height: 16px;
        text-align: right;
        font-style: normal;
    }
}
</style>
<style lang="scss">
.course {
    .course-tips {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #999999;
        line-height: 20px;
        text-align: left;
        font-style: normal;
    }
    .course-click {
        cursor: pointer;
        color: rgba(0, 132, 255, 1)
    }
}
</style>
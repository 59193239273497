import { ref } from "vue";

export const getBroswerName = () => {
    const nAgt = navigator.userAgent;
    let browserName = navigator.appName;
    let nameOffset, verOffset;
    console.log(nAgt, 'nAgt')
    // In Opera 15+, the true version is after "OPR/" 
    if ((verOffset = nAgt.indexOf("OPR/")) != -1) {
        browserName = "Opera";
    }
    // In older Opera, the true version is after "Opera" or after "Version"
    else if ((verOffset = nAgt.indexOf("Opera")) != -1) {
        browserName = "Opera";
    }
    // In MSIE, the true version is after "MSIE" in userAgent
    else if ((verOffset = nAgt.indexOf("MSIE")) != -1) {
        browserName = "Microsoft Internet Explorer";
    } else if ((verOffset = nAgt.indexOf("Edge")) != -1) {
        browserName = "Microsoft Edge";
    }
    // In Chrome, the true version is after "Chrome" 
    else if ((verOffset = nAgt.indexOf("Chrome")) != -1) {
        browserName = "Chrome";
    }
    // In Safari, the true version is after "Safari" or after "Version" 
    else if ((verOffset = nAgt.indexOf("Safari")) != -1) {
        browserName = "Safari";
    }
    // In Firefox, the true version is after "Firefox" 
    else if ((verOffset = nAgt.indexOf("Firefox")) != -1) {
        browserName = "Firefox";
    }
    // In most other browsers, "name/version" is at the end of userAgent 
    else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) <
        (verOffset = nAgt.lastIndexOf('/'))) {
        browserName = nAgt.substring(nameOffset, verOffset);
        if (browserName.toLowerCase() == browserName.toUpperCase()) {
            browserName = navigator.appName;
        }
    }
    return browserName
}

export function useVisitRecord() {
    const visitRecord = ref(new Set())
    
    // 获取访问记录
    const get = () => {
        if (localStorage.getItem('visitRecord')) {
            const value = JSON.parse(localStorage.getItem('visitRecord')!)
            if (Array.isArray(value)) {
                visitRecord.value = new Set(value)
            } else {
                visitRecord.value = new Set([])
            }
            return visitRecord
        } else {
            visitRecord.value = new Set([])
            return visitRecord
        }
    }
    // 判断是否已经访问过
    const has = (id: number): boolean =>  {
        return get().value.has(id)
    }
    // 保存访问记录
    const save = (id:number) => {
        const visitRecord = get()
        visitRecord.value.add(id)
        console.log(visitRecord, 'visitRecord')
        localStorage.setItem('visitRecord', JSON.stringify(Array.from(visitRecord.value)))
    }
    return {
        save,
        get,
        has
    }
}

<template lang="">
    <div class="identify container">
        <h3>{{storeName}} - AI 识别涂色卡</h3>
        <div class="camera">
            <span>摄像头选择：</span>
            <a-select
                :value="deviceId"
                ref="select"
                style="width: 200px"
                :disabled="disabled"
                :loading="loading"
                :placeholder="placeholder"
                @change="handleChange"
                size="large"
                >
                <a-select-option v-for="(device, index) in videoDevices" :key="index" :value="device.deviceId">{{device.label }}
                </a-select-option>
            </a-select>
            <a-button size="large" type="primary"  @click="getCamera" :disabled="disabled">检测设备</a-button>
            <span class="tips">请选择高拍仪对应的摄像头</span>
        </div>
        <div class="devices-show">
            <div class="image" v-if="videoDevices.length">
                <video ref="video" autoplay id="video" v-show="!imgUrl"></video>
                <div v-show="imgUrl">
                    <div :class="['scanning',scanning?'scanning-animation':''].join(' ')" v-if="scanning"></div>
                    <canvas ref="canvas" id="canvas" :style="identifyRes=='success'?{top: '-750px', zIndex: 4 }:{top: '0px', zIndex: 1}"></canvas>
                </div>
            </div>
            <div class="empty" v-else>
                <ExclamationCircleFilled :style="{ color:'#FAAD14', fontSize:'64px', paddingRight:'15px' }"/> 
                <span>没有连接摄像头（请检测设备）或是没有允许网页使用摄像头</span>
            </div>
        </div>
        <div v-if="identifyRes=='success'" class="success">
            <CheckCircleFilled :style="{ color:'#52C41A', fontSize:'50px', paddingRight:'16px' }"/>识别成功
        </div>
        <div v-else>
            <div class="btn">
                <a-button type="primary" size="large"  @click="takePhoto" v-if="!complete" :disabled="!deviceId">拍照</a-button>
                <a-button @click="redo" size="large" v-if="complete" class="photograph">重拍</a-button>
                <a-button type="primary" size="large" v-if="complete" @click="handleIdentify" :disabled="identify">AI 识别</a-button>
            </div>
            <p class="tips">请确保画面中包含完整的图画和二维码</p>
        </div>
    </div>
</template>
<script>
import { onBeforeMount, ref, onBeforeUnmount, getCurrentInstance } from 'vue';
import { CheckCircleFilled, ExclamationCircleFilled } from '@ant-design/icons-vue';
import { getBroswerName } from '@/utils/index'
import moment from 'moment'
import { message } from 'ant-design-vue';
import { gameLog, uploadBiologyV2 } from '@/api';
import Cookies from 'js-cookie';
import mqtt from 'mqtt'
const options = {
    clientId: 'mqtt_js_' + Math.random().toString(16).substr(2, 8),
    username: 'guest',
    password: 'test',
    reconnectPeriod: 1000, // 1 second reconnect interval
    connectTimeout: 30 * 1000, // 30 seconds connect timeout
    clean: true
};
export default {
    name: 'DistinguishPage',
    components: { CheckCircleFilled, ExclamationCircleFilled },
    setup() {
        const fillNumber = (number) => {
            if (typeof number !== 'string') {
                number = number.toString()
            }
            for (let i = 0; i < 4; i++) {
                if (number.length < 4) {
                    number = '0' + number
                } else {
                    break
                }
            }
            return number
        }
        const mqttClient = mqtt.connect('wss://mqtt.aimaker.space:8084/mqtt', options);
        mqttClient.on('connect', function () {
            console.log('Connected to MQTT broker');
        });

        mqttClient.on('error', function (err) {
            console.error('Connection error:', err);
        });

        mqttClient.on('reconnect', function () {
            console.log('Reconnecting...');
        });

        mqttClient.on('offline', function () {
            console.log('Client is offline');
        });

        mqttClient.on('close', function () {
            console.log('Connection closed');
        });

        document.addEventListener('keydown', function (event) {
            const keyName = event.key;
            let message = '';
            switch (keyName) {
                case '0':
                    message = '0';
                    break;
                case '1':
                    message = '1';
                    break;
                case '2':
                    message = '2';
                    break;
                case '3':
                    message = '3';
                    break;
                case '4':
                    message = '4';
                    break;
                case '5':
                    message = '5';
                    break;
                case '6':
                    message = '6';
                    break;
                case '7':
                    message = '7';
                    break;
                case '8':
                    message = '8';
                    break;
                case ' ':
                    message = 'Space';
                    break;
                default:
                    return; // 其他按键不处理
            }

            mqttClient.publish('keyboard' + fillNumber(store_id) + '/events', message, function (err) {
                if (err) {
                    console.error('Message not sent:', err);
                } else {
                    console.log('Message sent successfully');
                }
            });
        });
        message.config({
            top: `300px`,
            duration: 2,
            maxCount: 3,
            rtl: true,
            prefixCls: 'my-message',
        });
        const { proxy } = getCurrentInstance()
        const prefix = proxy.$prefix;
        const videoDevices = ref([])
        const disabled = ref(true)
        const loading = ref(true)
        const placeholder = ref('请选择摄像头')
        const deviceId = ref(null)
        const video = ref(null)
        const stream = ref(null)
        const canvas = ref(null)
        const complete = ref(false)
        const identify = ref(false)
        const imgUrl = ref('')
        const timer = ref(null)
        const identifyRes = ref(null)
        const scanning = ref(false)
        let controller = null;
        const store_id = JSON.parse(Cookies.get('elite_store_id'));
        const storeName = ref(Cookies.get('elite_store_name'));
        const redo = () => {
            message.destroy()
            complete.value = false
            imgUrl.value = null;
            disabled.value = false;
            identifyRes.value = ''
            getUserMedia(deviceId.value)
            controller && controller.abort()
        }

        const getUserMedia = (id) => {
            if (navigator.mediaDevices) {
                const play = () => {
                    navigator.mediaDevices.getUserMedia({
                        video: {
                            width: 1280,
                            height: 960,
                            deviceId: id,
                        }
                    })
                        .then(function (_stream) {
                            video.value.srcObject = _stream;
                            video.value.play();
                            stream.value = _stream
                            // interrupt.value = false
                            if (!id) {
                                navigator.mediaDevices.enumerateDevices().then(devices => {
                                    const _videoDevices = devices.filter(f => f.kind == 'videoinput')
                                    videoDevices.value = _videoDevices
                                    deviceId.value = _videoDevices[0].label
                                })
                            }
                        })
                        .catch(function (err) {
                            console.log('err', err);
                            // interrupt.value = true
                            videoDevices.value = []
                        });
                }
                play()
            } else {
                // interrupt.value = true
            }
        }
        const getCamera = () => {
            if (navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
                navigator.mediaDevices.enumerateDevices().then(devices => {
                    const _videoDevices = devices.filter(f => f.kind == 'videoinput')
                    videoDevices.value = _videoDevices
                    disabled.value = _videoDevices.length == 0
                    placeholder.value = _videoDevices.length == 0 ? '暂无设备' : '请选择摄像头'
                    loading.value = false
                    if (_videoDevices.length > 0) {
                        deviceId.value = _videoDevices[0].label
                        handleChange(deviceId.value)
                    }
                }).catch(err => {
                    console.log('出错', err);
                })
            }
        }
        const handleChange = (value) => {
            deviceId.value = value
            getUserMedia(value)

        };
        getCamera()
        const takePhoto = async () => {
            const width = video.value.videoWidth
            const height = video.value.videoHeight
            canvas.value.width = width
            canvas.value.height = height
            var context = canvas.value.getContext('2d');

            // 镜像翻转

            console.log('img-source', video.value);
            context.drawImage(video.value, 0, 0, width, height);

            imgUrl.value = canvas.value.toDataURL("image/jpeg");
            disabled.value = true
            complete.value = true
            if (stream.value) {
                stream.value.getTracks().forEach(track => {
                    track.stop();
                });
                stream.value = null
            }
        };
        const handleIdentify = () => {
            scanning.value = true
            identify.value = true
            controller = new AbortController()
            fetch(`${prefix}api/v1/elite_util/extract_frame_v2`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    image_base64: imgUrl.value,
                    is_horizontal: 1,
                    campus_id: store_id
                }),
                signal: controller.signal
            }).then(response => response.json()).then(res => {
                identify.value = false
                if (res.topic?.includes('radar')) {
                    const number = res.topic.split('/')[1];
                    uploadBiologyV2({
                        operating_system:navigator.platform.toLowerCase(),
                        browser: getBroswerName(),
                        fish_image: number,
                        image_src: imgUrl.value
                    }).then(res1 => {
                        if (res1.data.code !== 200) {
                            message.error(res1.data.msg);
                            identifyRes.value = 'fail'
                            identify.value = false
                        } else {
                            identifyRes.value = 'success'
                            setTimeout(() => {
                                redo()
                            }, 1200);
                        }
                        scanning.value = false
                    }).catch(error => {
                        message.error(error);
                        identifyRes.value = 'fail'
                        identify.value = false
                        scanning.value = false
                    })
                } else {
                    message.error(res.error);
                    identifyRes.value = 'fail'
                    identify.value = false
                    scanning.value = false
                }
            }).catch(() => {
                identify.value = false
            })
        }
        // 记录使用时长
        const recordUsedTime = () => {
            const format = 'YYYY-MM-DD HH:mm:ss'
            const begin_at = moment().subtract(15, 'minutes').format(format)
            const save_at = moment().format(format)
            gameLog({
                store_id: store_id,
                log_type: '100',
                begin_at,
                save_at
            })
        }
        onBeforeMount(() => {
            timer.value = setInterval(() => {
                recordUsedTime();
            }, 900000);
        })
        onBeforeUnmount(() => {
            clearInterval(timer.value)
        })
        return {
            takePhoto,
            handleIdentify,
            getCamera,
            videoDevices,
            disabled,
            loading,
            placeholder,
            handleChange,
            deviceId,
            video,
            canvas,
            stream,
            imgUrl,
            redo,
            complete,
            identify,
            timer,
            identifyRes,
            scanning,
            storeName
        }
    }
}
</script>
<style lang="scss">
.identify {
    width: 600px;
    font-size: 16px;
    color: #333333;

    h3 {
        font-weight: 600;
        font-size: 28px;
        color: #333;
        text-align: center;
        margin-top: 42px;
        margin-bottom: 50px;
    }

    .tips {
        color: #999999;
        text-align: center;
    }

    .camera {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 35px;
    }

    canvas {
        margin-bottom: 35px;
    }

    .devices-show {
        margin-bottom: 34px;

        .image {
            position: relative;
            height: 451px;

            video,
            canvas {
                max-width: 600px;
                object-fit: contain;
            }

            canvas {
                position: absolute;
                top: 0px;
                transition: all 1.2s;
                z-index: 1;
            }

            video {
                z-index: 0;
                background: #ccc;
                width: 600px;
                height: 451px;
            }

            .scanning {
                position: absolute;
                top: 0;
                width: 600px;
                height: 72px;
                background: linear-gradient(180deg, rgba(18, 110, 255, 0) 0%, rgba(55, 187, 255, 0.83) 100%);
            }
        }

        .empty {
            display: flex;
            align-items: center;
            width: 600px;
            height: 451px;
            padding: 0 125px;
            background: #ECECEC;
            border-radius: 19px;
            font-size: 16px;
            color: #333333;
            line-height: 28px;
        }

    }

    .success {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 22px;
        font-weight: 600;

    }

    .btn {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;

        button {
            width: 96px;
            margin: 0 20px;
        }
    }

    @keyframes scanningInProgress {
        from {
            top: 0;
        }

        to {
            top: 379px;
        }
    }

    .scanning-animation {
        animation: scanningInProgress 2s linear infinite;
        z-index: 2;
    }
}
</style>